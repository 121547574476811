



























































































































































































import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import Nullable from '@cellar-services/components/atoms/Nullable.vue';
import {
  SfImage,
  SfButton,
  SfRadio,
} from '@storefront-ui/vue';
import type { ItemInterface } from '@cellar-services/types';
import { STORED_STATUS, StoredStatus } from '~/bbrTheme/modules/catalog/constants/attributes/stored_status';
import { useCellarContentStore } from '@cellar-services/stores/cellar-content';
import { storeToRefs } from 'pinia';
import { ValidationObserver } from 'vee-validate';

const WITHDRAW = {
  CASE: '1',
  BOTTLE: '2',
  ALL: '3',
};

export default defineComponent({
  name: 'ArrangeDelivery',
  components: {
    Nullable,
    SfImage,
    SfButton,
    SfRadio,
    ValidationObserver,
  },
  props: {
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
  },
  setup(props) {
    const cellarContentStore = useCellarContentStore();

    const { arrangeDeliveryNote } = storeToRefs(cellarContentStore);

    const withdrawOption = ref<string>('1');
    const isPartCaseFeeApplicable = ref<boolean>(false);
    const quantity = ref<number>(1);

    // Set initial withdraw option based on availability
    if (props.item.cases_qty > 0) {
      withdrawOption.value = WITHDRAW.CASE;
    } else if (props.item.bottles_qty > 0) {
      withdrawOption.value = WITHDRAW.BOTTLE;
    }

    const quantityText = computed(() => {
      const suffix = withdrawOption.value === WITHDRAW.CASE
        ? `× case (${props.item.case_order_unit} x ${props.item.bottle_volume})`
        : `× bottle (${props.item.bottle_volume})`;

      return `${quantity.value} ${suffix}`;
    });

    // Maximum withdraw quantity, unit cases or unit bottles
    const maxQuantity = computed(() =>
      withdrawOption.value === WITHDRAW.CASE
        ? props.item.cases_qty
        : Number(props.item.case_order_unit) * props.item.cases_qty + props.item.bottles_qty
    );

    // Withdraw all bottles from the "rest" case
    // which contains all bottles that are not in full cases
    const canWithdrawAllBottles = (qty) => qty === props.item.bottles_qty;

    // If only whole cases, withdraw bottles in multiples of case size (whole case)
    const canWithdrawFullCasesOnly = (qty) => { 
      return qty % Number(props.item.case_order_unit) === 0
        && qty <= props.item.cases_qty * Number(props.item.case_order_unit)
    };

    // Withdraw full cases and all bottels
    const canWithdrawFulCasesAndAllBottles = (qty) => {
      const bottlesPerCase = Number(props.item.case_order_unit);
      const qtyOfFullCases = props.item.cases_qty * Number(props.item.case_order_unit);
      const qtyOfCasesPicked = Math.floor(qty / bottlesPerCase) > qtyOfFullCases
        ? qtyOfFullCases
        : Math.floor(qty / bottlesPerCase);
      const qtyAfterFullCases = qty - qtyOfCasesPicked * bottlesPerCase;

      return qtyAfterFullCases === props.item.bottles_qty;
    }

    watch(() => withdrawOption.value, (newOption) => {
      // Check initial state
      // Hide fee message if only one bottle available or if case contains one bottle
      quantity.value = 1;

      if (newOption === WITHDRAW.BOTTLE) {
        if (props.item.bottles_qty) {
          isPartCaseFeeApplicable.value = quantity.value !== props.item.bottles_qty
        } else { 
          isPartCaseFeeApplicable.value = quantity.value !== Number(props.item.case_order_unit);
        }
      } else { 
        isPartCaseFeeApplicable.value = false;
      }
    });

    // Check if part case fee should be applied based on case x bottle availability
    watch(() => quantity.value, (newQty) => {
      if (withdrawOption.value === WITHDRAW.BOTTLE) {
        if (canWithdrawAllBottles(newQty)) {
          isPartCaseFeeApplicable.value = false;
        } else if (canWithdrawFullCasesOnly(newQty)) {
          isPartCaseFeeApplicable.value = false;
        } else if (!canWithdrawFullCasesOnly(newQty) && !props.item.bottles_qty) {
          isPartCaseFeeApplicable.value = true;
        } else if (canWithdrawFulCasesAndAllBottles(newQty)) {
          isPartCaseFeeApplicable.value = false;
        } else {
          isPartCaseFeeApplicable.value = true;
        }
      }
    }, {
      immediate: true,
    });

    // If duty is pending on this cellar item
    const shouldPayDuty = props.item.stored_status !== StoredStatus.DutyPaid;

    return {
      STORED_STATUS,
      StoredStatus,
      arrangeDeliveryNote,
      shouldPayDuty,
      WITHDRAW,
      withdrawOption,
      isPartCaseFeeApplicable,
      quantity,
      maxQuantity,
      quantityText,
    }
  },
});
