













import {
  defineComponent,
  PropType,
} from '@nuxtjs/composition-api';
import Modal from '~/bbrTheme/components/organisms/Modal.vue';
import type { ItemInterface } from '@cellar-services/types';
import ArrangeDelivery from '@cellar-services/components/molecules/ModalContent/ArrangeDelivery.vue';

export default defineComponent({
  name: 'ArrangeDeliveryModal',
  components: {
    Modal,
    ArrangeDelivery,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object as PropType<ItemInterface>,
      required: true,
    },
  },
});
